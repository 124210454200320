/* chart */
.shape {
  fill-opacity: 0.6;
  cursor: pointer;
}

.shape:hover {
  fill-opacity: 0.8;
  z-index: 100;
}

.shape:active {
  fill-opacity: 1;
  z-index: 100;
}

.scale:nth-child(odd) {
  fill: white;
  stroke: #346634;
  stroke-width: 1;
}

.scale:nth-child(even) {
  fill: white;
  stroke: #346634;
  stroke-width: 1;
}

.axis {
  stroke: #555;
  stroke-width: 0.2;
}

.axis-big {
  stroke: #346634;
  stroke-width: 1;
}

.dot {
  fill: #f4b740;
  r: 4;
  transition: r 0.2s;
}

.dot:hover {
  stroke: #bbb;
  r: 8;
}

.caption {
  transition: all 0.1s;
  fill: #346634;
  text-shadow: 1px 1px 0 #fff;
  cursor: pointer;
  font-size: 8x;
}

.caption-group {
  width: 300px;
  height: 400px;
  cursor: pointer;
}

.caption-group:hover {
  text-decoration: underline;
  z-index: 1000;
}

.line-number {
  font-size: 10px;
  z-index: 1000;
}
