body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: Regular;
  src: url("./app/assets/MyriadPro-Regular.otf") format("opentype");
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: normal;
  src: url("./app/assets/MyriadPro-Regular.otf") format("opentype");
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: Bold;
  src: url("./app/assets/MyriadPro-Bold.otf") format("opentype");
  font-display: swap;
  font-weight: 700;
}

html,
body {
  font-size: 8px;
  background-color: #eeefed !important;
}

/* #mapid { height: 256px; } */

.leaflet-container {
  height: 256px;
  width: 336px;
}

/* .my-leaflet-map-container img {
  max-height: none;
} */

@media print {
  #noprint {
    display: none;
  } 

  #print {
    align-content: center;
    size: A4;
    width: 100%;
    margin-left: 3cm;
    margin-right: 3cm;
  }

  @page { margin: 0; }
  body { margin: 1.6cm; }
}

@media screen {
  #onlyprint {
    display: none;
  }
}